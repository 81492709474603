<template>
    <div>
        <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
                <template v-slot:header>
                    <h6 class="mb-0" v-if="!disabled">Tạo mới</h6>
                    <h6 class="mb-0" v-if="disabled">Chi tiết</h6>
                </template>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="Email (*)">
                            <b-form-input
                                v-model="input.email"
                                placeholder="Email"
                                :disabled="disabled"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Loại (*)">
                            <b-form-select
                                v-model="input.type_error"
                                :options="options.types"
                                :disabled="disabled"
                            ></b-form-select>
                        </b-form-group>
                        <b-form-group label="Mô tả">
                            <b-form-textarea
                                v-model="input.description"
                                placeholder="Mô tả"
                                :disabled="disabled"
                            ></b-form-textarea>
                        </b-form-group>
                        <b-form-group label="Nguồn" v-if="disabled">
                            <b-form-input
                                v-model="input.is_ipn"
                                placeholder="Nguồn"
                                :disabled="disabled"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Đồng bộ" v-if="disabled">
                            <b-form-input
                                v-model="input.is_sync"
                                placeholder="Email"
                                :disabled="disabled"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Thời gian lỗi" v-if="disabled">
                            <b-form-input
                                v-model="input.failed_at"
                                placeholder="Thời gian lỗi"
                                :disabled="disabled"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
<!--                    <b-col v-if="disabled && input.syncData">-->
<!--                        <div class="panel panel-default">-->
<!--                            <div class="panel-heading">Thông tin đồng bộ</div>-->
<!--                            <div class="panel-body">-->
<!--                                <b-form-group v-for="(item,ind) in input.syncData" :label="ind">-->
<!--                                    <b-form-input-->
<!--                                        :value="item"-->
<!--                                        placeholder="Thời gian lỗi"-->
<!--                                        :disabled="disabled"-->
<!--                                    ></b-form-input>-->
<!--                                </b-form-group>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </b-col>-->
                </b-row>
                <b-row v-if="!disabled">
                    <b-col class="text-center">
                        <b-button class="mr-2" variant="outline-primary" @click="store()">Thêm mới</b-button>
                        <b-button class="mr-2" variant="outline-danger" @click="cancel()">Hủy</b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import "vue2-datepicker/index.css";
import CmsRepository from "@/core/repositories/cmsRepository";
import moment from 'moment';

Vue.use(Notifications);
export default {
    mixins: [Common],
    data() {
        return {
            disabled: false,
            input: {
                email: null,
                type_error: "BOUNCE",
                syncData:[]
            },
            options: {
                types: [
                    {value: "BOUNCE", text: "BOUNCE"},
                    {value: 'COMPLAINT', text: "COMPLAINT"},
                ],
            },
        };
    },
    async created() {

    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Config bounce mail", route: '/tools/bounce-mail'},
        ]);
        if (this.$route.query.id != '' && this.$route.query.id != null) {
            this.getDetail(this.$route.query.id)
            this.disabled = true;
        }
    },
    methods: {
        cancel() {
            return this.$router.push({name: "bounceMail"});
        },
        validateStoreForm() {
            if (this.input.email === "" || this.input.email === null) {
                this.notifyAlert("warn", "email không được để trống");
                return false;
            }
            if (!this.validateEmail(this.input.email)) {
                this.notifyAlert("warn", "email không đúng định dạng");
                return false;
            }
            return true;
        },
        store() {
            if (!this.validateStoreForm()) {
                return;
            }
            let inputParam = {...this.input};
            this.$bus.$emit("show-loading", true);
            CmsRepository.storeBounceMail(inputParam)
                .then((response) => {
                    if (response) {
                        this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({name: "bounceMail"});
                    } else {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return false;
                    }
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        async getDetail(id) {
            this.$bus.$emit("show-loading", true);
            await CmsRepository.detailBounceMail(id)
                .then((response) => {
                    if (response.data.error_code !== 0) {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return false;
                    }

                    let result = response.data.data;
                    this.input.email = result.email;
                    this.input.description = result.description;
                    this.options.types.push({
                        'text':result.type_error,
                        'value':result.type_error
                    });
                    this.input.type_error = result.type_error;
                    this.input.is_sync = result.is_sync ? "Đã đồng bộ" : "Chưa đồng bộ";
                    this.input.is_ipn = (result.is_ipn == 1) ? "IPN" : "Import";
                    this.input.failed_at = moment(String(result.failed_at)).format('DD/MM/YYYY hh:mm');
                    this.input.syncData = result.syncData;
                    this.$bus.$emit("show-loading", false);
                })
                .catch((error) => {
                    alert(error);
                    this.$bus.$emit("show-loading", false);
                });
        },
        validateEmail (email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        }
    },
};
</script>


